import loadable from '@loadable/component'
import { RouteComponentProps } from 'react-router'

export interface RouteConfigProps {
  path: string,
  exact: boolean,
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  id: number,
  name?: string,
  routes?: Array<RouteConfigProps>
}

const Index = loadable(() => import('../views/index'))
const Login = loadable(() => import('../views/login'))

const ClusterIndex = loadable(() => import('../views/train'))
const Cluster = loadable(() => import('../views/train/clusters'))
const CreateCluster = loadable(() => import('../views/train/clusters/_creat-cluster'))
const ClusterDetail = loadable(() => import('../views/train/clusters/clusters-detail'))
const ClusterQueue = loadable(() => import('../views/train/clusters/clusters-detail/queue'))
const NodeMonitor = loadable(() => import('../views/train/clusters/clusters-detail/monitor'))
const NodeList = loadable(() => import('../views/train/clusters/nodes'))

const JobDetail = loadable(() => import('../views/train/jobs'))
const JobOverview = loadable(() => import('../views/train/jobs/_overview'))
const CreateJob = loadable(() => import('../views/train/jobs/_create-job'))
const Pods = loadable(() => import('../views/train/jobs/_pods'))
const Monitor = loadable(() => import('../views/train/jobs/_monitor'))
const Tensorboard = loadable(() => import('../views/train/jobs/_tensorboard'))
const PodDetail = loadable(() => import('../views/train/jobs/pod-detail'))
const PodOverview = loadable(() => import('../views/train/jobs/pod-detail/_overview'))
const PodLog = loadable(() => import('../views/train/pods/log'))
const Podssh = loadable(() => import('../views/train/pods/_pod-ssh'))

const JobTotal =  loadable(() => import('../views/train/jobs/job-list'))

const DataManage = loadable(() => import('../views/data-manage'))
const Datasets = loadable(() => import('../views/data-manage/datasets'))
const Models = loadable(() => import('../views/data-manage/models'))

const UserManage = loadable(() => import('../views/user-manage'))
const Users = loadable(() => import('../views/user-manage/users'))
const UserGroup = loadable(() => import('../views/user-manage/group'))

const Image = loadable(() => import('../views/image'))
const Info = loadable(() => import('../views/info'))

export const routeConfig: Array<RouteConfigProps> = [
  {
    path: '/login',
    exact: false,
    id: 1000,
    component: Login,
  },
  {
    path: '/',
    exact: false,
    component: Index,
    id: 999,
    name: 'Home',
    routes: [
      {
        path: '/train',
        exact: false,
        id: 1,
        name: '集群中心',
        component: ClusterIndex,
        routes: [
          {
            path: '/train/clusters',
            exact: false,
            component: Cluster,
            id: 11,
            name: '集群列表',
          }, {
            path: '/train/jobs',
            exact: false,
            component: JobTotal,
            id: 12,
            name: 'job列表',
          }, {
            path: '/train/cluster/:id/:clusterName',
            exact: false,
            component: ClusterDetail,
            id: 13,
            name: '集群详情',
            routes: [
              {
                path: '/train/cluster/:id/:clusterName/queue',
                exact: false,
                component: ClusterQueue,
                id: 131,
                name: 'queue',
              },
              {
                path: '/train/cluster/:id/:clusterName/node-list',
                exact: false,
                component: NodeList,
                id: 132,
                name: 'NodeList',
              }
            ]
          }, {
            path: '/train/job/:clusterId/:clusterName/:jobId/:jobName',
            exact: false,
            component: JobDetail,
            id: 14,
            routes: [
              {
                path: '/train/job/:clusterId/:clusterName/:jobId/:jobName/overview',
                exact: false,
                component: JobOverview,
                id: 141
              },
              {
                path: '/train/job/:clusterId/:clusterName/:jobId/:jobName/pods',
                exact: false,
                id: 142,
                component: Pods,
              },
              {
                path: '/train/job/:clusterId/:clusterName/:jobId/:jobName/monitor',
                exact: false,
                id: 143,
                component: Monitor,
              },
              {
                path: '/train/job/:clusterId/:clusterName/:jobId/:jobName/tensorboard',
                exact: false,
                id: 143,
                component: Tensorboard,
              }
            ],
          }, {
            path: '/train/pod/:clusterId/:clusterName/:jobId/:jobName/:podId/:podName',
            exact: false,
            component: PodDetail,
            id: 17,
            routes: [
              {
                path: '/train/pod/:clusterId/:clusterName/:jobId/:jobName/:podId/:podName/overview',
                exact: false,
                id: 171,
                component: PodOverview,
              },
              // {
              //   path: '/train/pod/:clusterId/:clusterName/:jobId/:jobName/:podId/:podName/log',
              //   exact: false,
              //   id: 172,
              //   component: PodLog,
              // }, 
              {
                path: '/train/pod/:clusterId/:clusterName/:jobId/:jobName/:podId/:podName/ssh',
                exact: false,
                id: 173,
                component: Podssh,
              }
            ]
          }, {
            path: '/train/cluster/create',
            exact: false,
            component: CreateCluster,
            id: 15,
            name: '创建集群',
          },
          {
            path: '/train/job/create',
            exact: false,
            component: CreateJob,
            id: 16,
            name: '创建任务',
          }, {
            path: '/train/monitor/:id/:clusterName/:gpuName',
            exact: false,
            component: NodeMonitor,
            id: 18,
            name: 'NodeMonitor'
          }
        ]
      },
      {
        path: '/data',
        exact: false,
        id: 2,
        name: '数据中心',
        component: DataManage,
        routes: [
          {
            path: '/data/datasets',
            exact: false,
            component: Datasets,
            id: 21,
            name: '数据集',
          }, {
            path: '/data/models',
            exact: false,
            component: Models,
            id: 22,
            name: '模型',
          }
        ],
      },
      {
        path: '/user',
        exact: false,
        id: 6,
        name: '用户中心',
        component: UserManage,
        routes: [
          {
            path: '/user/users',
            exact: false,
            component: Users,
            id: 61,
            name: '用户',
          }, {
            path: '/user/groups',
            exact: false,
            component: UserGroup,
            id: 62,
            name: '用户组',
          }
        ],
      },
      {
        path: '/image',
        exact: false,
        id: 3,
        name: '镜像',
        component: Image,
      },
      {
        path: '/wiki',
        exact: false,
        id: 4,
        name: '操作指南',
        component: Info,
      },
      {
        path: '/log/:podName/:podId',
        exact: false,
        id: 5,
        component: PodLog,
      }
    ]
  }
]