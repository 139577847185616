/**
 * 为不支持 localStorage 或处于私密模式的浏览器添加简单的 localStorage 支持
 * export 统一的 localStorage 接口
 */

try {
  window.localStorage.setItem('_test_key_', '1')
  window.localStorage.removeItem('_test_key_')
  module.exports = window.localStorage
} catch (error) {
  module.exports = {
    _data: {},
    setItem(id, val) { this._data[id] = String(val) },
    getItem(id) { return this._data.hasOwnProperty(id) ? this._data[id] : undefined },
    removeItem(id) { return delete this._data[id] },
    clear() { this._data = {} },
  }
}
