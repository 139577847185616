import React from 'react';
import { Provider } from 'react-redux';
import zhCN from 'antd/es/locale-provider/zh_CN'
import { HashRouter as Router } from 'react-router-dom'
import { MyRouter } from './router'
import { ConfigProvider, message, notification } from 'antd'
import storeConfig from './store'
import moment  from 'moment'
import 'moment/locale/zh-cn'
import 'antd/dist/antd.css'
import './App.css';
import './assets/sass/main.sass'
import { checkLogin } from './utils/user'
// import toUserCenter from './utils/user-center'
moment.locale('zh-cn')
const store = storeConfig()

message.config({ // antd的message组件配置，during持续时间单位为秒，maxcount最多显示个数
  duration: 2,
  maxCount: 2
});
notification.config({
  placement: 'topRight', // antd的通知组件配置，placement弹出位置。bottom距离底部的距离，during持续时间单位为秒
  bottom: 50,
  duration: 2,
});

function App() {
  // toUserCenter()
  // if (!checkLogin()) toUserCenter()
  if (!checkLogin()) {
    window.location.href = '#/login'
  }
  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <MyRouter />
        </Router>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
