import React from 'react'
import {Switch, HashRouter as Router, Redirect } from 'react-router-dom'
import {routeConfig} from './routerConfig'
import {SubRoute} from './subRoute'

export const MyRouter = function () {
  return (
    <Router>
      <Switch>
        <Redirect from="/" to="/login" exact/>
        {
          routeConfig.map(route => {
            return <SubRoute key = {route.id} {...route} />
          })
        }
        <Redirect from="*" to="/login"/>
        {/* <Route exact path="/" render={() => <Redirect to="/train/clusters" push />} />
        <Redirect from="/*" to="/" /> */}
      </Switch>
    </Router>
  )
}
