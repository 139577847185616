import browserStorage from './local-storage'
import _ from 'lodash'
import storeConfig from '../store'


// token存取
// token 只会存储在local内部, 退出时需要主动清理
function saveToken(token: string) {
  if (!token) return
  browserStorage.setItem('token', token)
}

export function getToken(): string | null {
  return browserStorage.getItem('token')
}

// 存储用户信息
export function saveUser(userData: { username?, role?, token?, uid? } = {}) {
  if (userData.token) saveToken(userData.token)
  if (userData.username) saveUserName(userData.username)
  if (userData.role) saveRole(userData.role)
  if (userData.uid) saveUid(userData.uid)
}

// UID
function saveUid(uid: string) {
  browserStorage.setItem('uid', uid)
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: { uid: uid }
    }
  }
  store.dispatch(action())
}
export function getUid(): string {
  const store = storeConfig()
  const uid = store.getState().myReducer.uid
  if (uid) return uid
  return browserStorage.getItem('uid') || ''
}


// 用户角色存取
function saveRole(roleName: string) {
  browserStorage.setItem('userRole', roleName)
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: { role: roleName }
    }
  }
  store.dispatch(action())
}
export function getRole(): string {
  const store = storeConfig()
  const storeRole = store.getState().myReducer.role
  if (storeRole) return storeRole
  return browserStorage.getItem('userRole') || 'normal'
}

// 用户名称存取
function saveUserName(username: string) {
  browserStorage.setItem('userName', username)
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: { userName: username }
    }
  }
  store.dispatch(action())
}
export function getUserName(): string {
  const store = storeConfig()
  const storeName = store.getState().myReducer.userName
  if (storeName) return storeName
  return browserStorage.getItem('userName') || ''
}

// 存取job筛选条件
export function saveJobFilter(jobFilter: object) {
  browserStorage.setItem('jobFilter', JSON.stringify(jobFilter))
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: { jobFilter: JSON.stringify(jobFilter) }
    }
  }
  store.dispatch(action())
}
export function getJobFilter(): string {
  const store = storeConfig()
  const storeJobFilter = store.getState().myReducer.jobFilter
  if (storeJobFilter) return storeJobFilter
  return browserStorage.getItem('jobFilter') || ''
}

// 存取job range
export function saveJobRange(range: string) {
  browserStorage.setItem('jobRange', range)
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: { jobRange: range }
    }
  }
  store.dispatch(action())
}
export function getJobRange(): string {
  const store = storeConfig()
  const storeJobRange = store.getState().myReducer.jobRange
  if (storeJobRange) return storeJobRange
  return browserStorage.getItem('jobRange') || ''
}

// 获取当前用户信息
export function getUser() {
  const username = getUserName()
  const role = getRole()
  const token = getToken()
  return { username, token, role }
}

// 清理用户信息
export function removeUser() {
  browserStorage.removeItem('userName')
  browserStorage.removeItem('token')
  browserStorage.removeItem('userRole')
  browserStorage.removeItem('uid')
  browserStorage.removeItem('jobFilter')
  browserStorage.removeItem('jobRange')
  const store = storeConfig()
  const action = () => {
    return {
      type: 'STORE_DATA',
      payload: {
        userName: '',
        role: '',
        uid: '',
        jobRange: '',
        jobFilter: ''
      }
    }
  }
  store.dispatch(action())
}

// 判断是否登录
export function checkLogin() {
  const userData = getUser()
  return _.every(userData, Boolean)
}